<template>
  <div class="select">
    <select v-model="$i18n.locale" @change="changeLang">
      <option value="en">English</option>
      <!-- <option value="zh">中文</option> -->
      <option value="fr">France</option>
      <option value="de">Deutschland</option>
      <option value="it">Italia</option>
      <option value="pt">Portugal</option>
      <option value="es">español</option>
    </select>
  </div>
  <div class="bg mask flex-column center">
    <div class="logo">
      <img style="width: 280px;" src="/logo2.png" alt="" />
    </div>
    <p class="p">{{ $t("welcome") }}</p>
  </div>
</template>

<script>
export default {
  setup() {
    return { locale: "en" };
  },
  methods: {
    async created() {}
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.bg {
  // background: #556a78;
  background-color: #556a78;/*背景色*/
			background-image: linear-gradient(90deg, rgba(57, 67, 74, 0.15) 2%, rgba(0, 0, 0, 0) 2%), linear-gradient(0deg, rgba(57, 67, 74, 0.15) 2%, rgba(0, 0, 0, 0) 2%);/*rgba(0, 0, 0, 0)，transparent全透明黑*/
			background-size: 150px 150px;/*调节格子宽 高*/
      background-position: 25%;
}
.p {
  max-width: 500px;
  padding: 40px;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  text-align: center;
}
.select {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9999;
  color: #fff;
  select {
    border-radius: 40px;
    padding-left: 10px;
    border: none;
    color: #666;
    line-height: 26px;
    height: 26px;
  }
}
</style>
